$em: 18px;
$spacing: 10px;

@import "~bootstrap/scss/bootstrap";
@import 'styles/doorkeeper';

@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';
@import 'styles/buttons';
@import 'styles/forms';

body {
  height: 100vh;
  background-color: $sc-dark-gray;
}

#container {
  min-width: 0;
  width: 100%;
  border: none;
  background-color:inherit;
  box-shadow: none;
}

.sc-logo {
  width: 158px;
  height: 47px;
  background: url('~images/logos/small_white_logo.png') 0 0 no-repeat;
  text-indent: -9999px;
  margin: auto;
  margin-bottom: 40px;
}

body.controller-auth-providers-local {
  .page-header { display: none; }
  .login-form {
    max-width: 480px;
    margin: auto;
  }
}

form .actions {
  float: right;
  margin: 16px;
}

body.controller-auth-users {
  .actions, .page-header {
    text-align: center;
    margin: $em;
  }
}

body.controller-oauth-authorizations.action-new {
  .page-header {
    display: none;
  }
}

body.controller-oauth-authorizations-failure {
  .page-header {
    display: none;
  }

  div[role=main], main {
    text-align: center;
    margin: $em;
  }
}

div[role=main], main {
  div.actions {
    border: none
  }
}

.page-header {
  border: none;
}
