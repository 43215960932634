$headline-font: "Montserrat", helvetica, arial, sans-serif;
$body-font: "Open Sans", helvetica, arial, sans-serif;
$bold-font: "Open Sans Bold", helvetica, arial, sans-serif;
$line-height: 1.6;

body {
  color: #fff;
  font-weight: 300;
  // background: $body-color;
  font-family: $body-font;
  font-size: 14px;
  text-rendering: optimizeLegibility;
  line-height: $line-height;
}

h1, h2, h3, h4, h5, h6, p {
  font-weight: normal;
  padding-bottom: $em*0.5;

  .no-padding {
    padding: 0px;
  }
}

h1, h2, h3 {
  font-family: $headline-font;
  // @include text-shadow(1px 1px 0px #fff);
}

h4,h5,h6,p{
 font-family: $body-font;
}

h1 {
  font-size: $em*1.5;
}

h2 {
  font-size: $em*1.2;
}

h3 {
  font-size: $em;
}

h4 {
  font-size: $em*0.75;
}

h5 {
  // @include small-text;
  font-size: $em*0.857;
}

h6 {
  font-size: $em*0.67;
}

a {
  // color: $primary-button-base-color;
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}

a.plain:hover {
  text-decoration: none;
}


.text-info {
  color: #000!important;
}
