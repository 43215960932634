$input-top-padding: 1.3*$spacing;
$input-side-padding: 1.5*$spacing;




.input-container {
  // added values are for the border and box-shadow
  margin-top: $input-top-padding + 3;
  // margin-bottom: $input-top-padding;
  // margin-right: $input-side-padding + 2;
  margin-left: $input-side-padding;
}

.input,
input[type=text],
input[type=password],
input[type=email],
textarea {
  color: #333;
  display: block;
  width: 100%;

  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  text-shadow: none;

  background-color: #fff;
  font-size: $em;
  padding: $input-top-padding  $input-side-padding;
  margin-left: -$input-side-padding;
  margin-top: -$input-top-padding;


  @include bordered;
  // @include box-shadow(inset 0px 2px 0px #ccc);
  // @include border-radius;

  &:focus {
    padding: $input-top-padding - 1px $input-side-padding - 1px;
    border: 2px solid $sc-dark-blue;
  }
}

select {
  color: #333;
}

input[type=checkbox] {
  // TODO: see about building something custom with Marc
  -webkit-appearance: checkbox;
}

textarea {
  min-height: 100px;
}

@-moz-document url-prefix() {
  textarea {
    font-family: $body-font;
  }
}

label {
  color: #fff;
  // margin-bottom: 15px;
  margin: 0;
  // padding-left: 0;
  font-weight: 300;
}

form {
  .line, .row, .last-unit,
  .s-row {
    width: 100%;
    display: block;
    overflow: hidden;
    *overflow: visible;
    *zoom: 1;
  }
  .row {
    margin-left: 0;
    margin-right: 0;
  }
}
