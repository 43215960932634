$sc-blue: #415b72;
$sc-dark-blue: #232f3c;
$sc-light-blue: #667f99;
$sc-dark-gray: #5D636E;
$sc-teal: #16a085;
$sc-dark-teal: #11856e;


$sc-button-primary: $sc-blue;
$sc-button-primary-hover: #34495e;
$sc-button-primary-disabled: #9cb3c9;
$sc-button-primary-focus: $sc-light-blue;
