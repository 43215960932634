
.btn {
  border: none;
  border-radius: 0px;
  font-size: $em;
  font-weight: 300;
  letter-spacing: 1px;
  padding: 12px 30px;
  white-space: normal;
}

.btn-primary {
  background-color: $sc-blue;
  &:hover{
    background-color: #467eb9;
  }
}

.btn-success {
  background-color: $sc-teal;
  &:hover{
    background-color: $sc-dark-teal;
  }
}


.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
